import React, {createRef, useEffect} from "react";
import {Link} from "gatsby";
import {MenuItemProps} from "../interfaces/menu-item-props";
import {isActiveMenuItem} from "../util/menu";

const navItems: MenuItemProps[] = [
    {
        label: 'Familienrecht',
        href: '/rechtsgebiete/familienrecht/',
        classList: []
    },
    {
        label: 'Erbrecht',
        href: '/rechtsgebiete/erbrecht/',
        classList: []
    },
    {
        label: 'Mietrecht',
        href: '/rechtsgebiete/mietrecht/',
        classList: []
    },
    {
        label: 'Verkehrsrecht',
        href: '/rechtsgebiete/verkehrsrecht/',
        classList: []
    },
    {
        label: 'Inkasso',
        href: '/rechtsgebiete/inkasso/',
        classList: []
    },
    {
        label: 'Strafrecht',
        href: '/rechtsgebiete/strafrecht/',
        classList: []
    }
];


interface RechtsgebieteMenuProps {
    onMenuLinkChange: Function;
}

const RechtsgebieteMenu: React.FC<RechtsgebieteMenuProps> = (props: RechtsgebieteMenuProps) => {

    const sidebarMenu = createRef();
    const activeMenuItem = createRef();


    const onMenuLinkClick = () => {
        props.onMenuLinkChange();
    };

    useEffect(() => {

        if (!sidebarMenu.current || !activeMenuItem.current || window.innerWidth >= 1024) return;

        const containerPadding = window.innerWidth - sidebarMenu.current.clientWidth;
        const position = activeMenuItem.current.offsetLeft - (containerPadding / 2) - (sidebarMenu.current.clientWidth / 2) + (activeMenuItem.current.clientWidth / 2);

        sidebarMenu.current.scroll(position, 0);
    });

    return (
        <div id={'sidebar-menu'}
             className="mb-6 lg:mb-0 flex gap-2 lg:-mt-3 lg:gap-0 overflow-x-auto lg:flex-wrap lg:flex-col lg:w-48"
             ref={sidebarMenu}>
            {navItems.map((item, i) => {
                return (
                    <div
                        className={['menu-item bg-red lg:bg-transparent md:mr-0 xl:mr[75px] border-t border-t-white lg:border-0', ...item.classList].join(' ')}
                        key={i}>
                        <Link to={item.href}
                              title={item.title ? item.title : item.label}
                              className={`px-4 lg:px-0 py-1 lg:py-3 w-full block lg:px-1 text-white lg:text-black lg:text-xl leading-8 hover:font-bold ${(isActiveMenuItem(item.href)) ? 'font-bold active' : ''}`}
                              onClick={onMenuLinkClick}
                              ref={isActiveMenuItem(item.href) ? activeMenuItem : null}
                        >
                            {item.label}
                        </Link>
                    </div>
                )
            })}
        </div>
    );
};

export default RechtsgebieteMenu;
