import React from 'react';
import Footer from './Footer';
import Header from "./Header";
import RechtsgebieteMenu from "./RechtsgebieteMenu";

interface LayoutRechtsgebiete {
    children?: React.ReactNode;
}

const Layout: React.FC<LayoutRechtsgebiete> = ({children}) => {

    const onMenuLinkChange = () => {

    }

    return (
        <>
            <Header></Header>
            <main id="main" className="container">
                <div
                    className="bg-white flex flex-col lg:flex-row py-10 px-8 md:px-16 lg:py-20 lg:px-32 overflow-x-hidden">
                    <div>
                        <RechtsgebieteMenu onMenuLinkChange={onMenuLinkChange}></RechtsgebieteMenu>
                    </div>
                    <div className={'lg:pl-10 xl:pl-16'}>
                        {children}
                    </div>
                </div>
            </main>
            <Footer></Footer>
        </>
    );
};

export default Layout;

export const Head = () => {

}
