import * as React from "react"
import SEO from "../../components/seo";
import LayoutRechtsgebiete from "../../components/LayoutRechtsgebiete";


export default () => {

    return (<LayoutRechtsgebiete>
        <div id="content" className="animate-fade-in">
            <h1>Familienrecht</h1>
            <ul>
                <li> Elterliche Sorge und Umgang</li>
                <li> Ehevertrag</li>
                <li> Güterrecht</li>
                <li> Scheidung auch mit Auslandsbezug</li>
                <li> Unterhaltsrecht</li>
                <li> Vermögensrechtliche Auseinandersetzung außerhalb des Güterrechts</li>
                <li> Versorgungsausgleich</li>
            </ul>
            <p>
                Das Familienrecht regelt die Rechtsverhältnisse derjenigen, die durch Ehe und Verwandtschaft miteinander
                verbunden sind. Beratung und Information sind im Bereich des Familienrechts von besonderer Wichtigkeit,
                weil hier weit reichende Entscheidungen zu treffen sind.
            </p>
            <p>
                Güterrechtliche Ansprüche und Unterhaltsansprüche sowie die Regelung des Umgangs- und Sorgerechts können
                als sogenannte Scheidungsfolgensache auf Antrag im Verbund mit der Scheidung geltend gemacht werden.
                Regelmäßig können nur nach eingehender Information und interessengerechter Beratung hier sachgerechte
                Entscheidungen getroffen werden.
            </p>
            <p>
                Zu klären ist oft zunächst welche berechtigten Unterhaltsansprüche bestehen, zur Sicherstellung des
                Lebensbedarfs des berechtigten Ehegatten und der Kinder. Die Feststellung von Unterhaltsansprüchen ist
                oft kompliziert und hängt von einer Vielzahl von Faktoren ab.
            </p>
            <p>
                Zu beachten ist hierbei insbesondere auch, dass das seit dem 1. Januar 2008 geltende neue
                Unterhaltsrecht weit reichende Veränderungen herbeigeführt hat hinsichtlich der Rangfolge der
                Unterhaltsberechtigten und der Zumutung nachehelicher Eigenverantwortung.
            </p>
            <p>
                Für einen juristischen Laien bedeutet dies in der Regel einen Berg von unübersichtlichen Regelungen.
                Ich helfe Ihnen gern nicht den Überblick zu verlieren und nicht Gefahr zu laufen von einer Partei
                übervorteilt zu werden.
            </p>
            <p>
                Sind Kinder vorhanden, ist die Regelung des Sorgerechts und des Umgangsrechts von enormer Wichtigkeit.
                Um Kinder in der Trennungsphase jedoch nicht noch weiter zu belasten, sollte offener Streit über diese
                Themen vermieden werden. Wir unterstützen Sie im Sinne des sogenannten Cochemer Modells, taktisch klug,
                überlegt und im Sinne der Kinder zu handeln.
            </p>
            <p>
                Darüber hinaus gehört zum Familienrecht auch die Beratung im Vorfeld von Trennung und Scheidung und in
                diesem Zusammenhang die Gestaltung von Eheverträgen, Trennungs- und Scheidungsfolgenvereinbarungen. Von
                der Erstberatung während der Trennungsphase bis zur Abwicklung des Scheidungsverfahrens werden Sie in
                dieser Phase der Krisensituation von mir gerne juristisch unterstützt.
            </p>
        </div>
    </LayoutRechtsgebiete>)
};

export const Head = () => {
    return (<SEO title={'Familienrecht'}/>);
}
